export const config = {
  APP_VERSION: import.meta.env.APP_VERSION,
  GTAG_ID: import.meta.env.VITE_GTAG_ID,
  AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: import.meta.env.VITE_AUTH0_AUDIENCE,
  MY_CALLBACK_URL: import.meta.env.VITE_MY_CALLBACK_URL,
  MY_LOGOUT_URL: import.meta.env.VITE_MY_LOGOUT_URL,
  MYPAGE_URL: import.meta.env.VITE_MYPAGE_URL,
  APP_STATUS_URL: import.meta.env.VITE_APP_STATUS_URL,
  EBONE_API_URL: import.meta.env.VITE_EBONE_API_URL,
  EBONE_API_STATUS_URL: import.meta.env.VITE_EBONE_API_STATUS_URL,
  EBONE_UKETSUKE_FORM_URL: import.meta.env.VITE_EBONE_UKETSUKE_FORM_URL,
  ES_ACCOUNT_APP_URL: import.meta.env.VITE_ES_ACCOUNT_APP_URL,
  EB_BUKKEN_APP_URL: import.meta.env.VITE_EB_BUKKEN_APP_URL,
  EB_SQUARE_APP_URL: import.meta.env.VITE_EB_SQUARE_APP_URL,
  SUMAI_ENTRY_APP_URL: import.meta.env.VITE_SUMAI_ENTRY_APP_URL,
  EB_COMMON_SUPPORT_URL: import.meta.env.VITE_EB_COMMON_SUPPORT_URL,
  EB_SFA_SUPPORT_URL: import.meta.env.VITE_EB_SFA_SUPPORT_URL,
  ES_ACCOUNT_SUPPORT_URL: import.meta.env.VITE_ES_ACCOUNT_SUPPORT_URL,
  FULLSTAR_UUID: import.meta.env.VITE_FULLSTAR_UUID,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  SENTRY_PACKAGE_NAME: import.meta.env.VITE_SENTRY_PACKAGE_NAME,
  SMS_SHORTED_URL_LENGTH: import.meta.env.VITE_SMS_SHORTED_URL_LENGTH,
  CSV_FEATURE_FLAG: import.meta.env.VITE_CSV_FEATURE_FLAG,
};

/** サービスサイト URL */
export const SERVICE_SITE_URL = 'https://www.es-service.net';

/** コーポレートサイト URL */
export const CORPORATE_SITE_URL = 'https://www.e-seikatsu.info';

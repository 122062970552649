import { useAuth0 } from '@auth0/auth0-react';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { Theme as MUITheme } from '@mui/material/styles';
import { UnNotifiedError, ApiError } from 'eb-sfa-core';
import {
  ConfirmDialogContextProvider,
  Loading,
  useEnqueueSnackbar,
} from 'eb-sfa-frontend-ui';
import { useEffect, useState } from 'react';
import { useNavigate, useRoutes, useLocation } from 'react-router-dom';
import { SWRConfig } from 'swr';

import LoginError from '@/Components/Login/LoginError';
import { NotificationsContextProvider } from '@/Contexts/NotificationsContextProvider';
import { DependencyProvider } from '@/Hooks/DependencyHook';
import routes, { errorRoutes, missingInitializedDbRoute } from '@/Routes';
import type { Services } from '@/Services/SetupDependencies';
import { isMissingInitialize } from '@/Utils/CommunicationUtils';
import { isMoveScrollTop } from '@/Utils/RouteUtils';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/consistent-type-definitions
  export interface Theme extends MUITheme {}
}

const InputGlobalStyles = () => (
  <GlobalStyles
    styles={{
      ['*']: {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        color: 'rgba(0,0,0,0.87)',
        backgroundColor: '#f4f6f8',
        height: '100%',
        width: '100%',
        fontSize: '14px',
      },
      a: {
        textDecoration: 'none',
      },
      ['#root']: {
        height: '100%',
        width: '100%',
      },
    }}
  />
);

const errorPaths = errorRoutes.map(route => route.path);

const App = (): JSX.Element => {
  const navigate = useNavigate();
  const routing = useRoutes(routes);
  const [services, setServices] = useState<Services | null>(null);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { getAccessTokenSilently, isAuthenticated, isLoading, error } =
    useAuth0();
  const location = useLocation();
  const [locationState, setLocation] = useState(location);
  const isErrorPage = errorPaths.includes(location.pathname);

  useEffect(() => {
    import('./Services/SetupDependencies')
      .then(async module => module.setupDependencies(getAccessTokenSilently))
      .then(services => {
        setServices(services);
      });
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (location === locationState) {
      return;
    }
    if (isMoveScrollTop(locationState.pathname, location.pathname)) {
      window.scrollTo({ top: 0 });
    }
    setLocation(location);
  }, [location, locationState]);

  if (error) {
    return <LoginError />;
  }
  if (isLoading || !services) {
    return <Loading />;
  }

  const onError = (error: Error) => {
    if (isMissingInitialize(error)) {
      if (!isErrorPage) {
        navigate(missingInitializedDbRoute.path);
      }
      return;
    } else if (
      error instanceof UnNotifiedError ||
      (error instanceof ApiError && error.response.code === 'common-0013')
    ) {
      // スナックバーによる通知が必要のないエラーはスルー
      return;
    }
    enqueueSnackbar('データの取得に失敗しました', {
      variant: 'error',
    });
  };

  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        focusThrottleInterval: 0,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        onError,
      }}
    >
      <DependencyProvider services={services}>
        <CssBaseline />
        <InputGlobalStyles />
        <NotificationsContextProvider>
          <ConfirmDialogContextProvider>{routing}</ConfirmDialogContextProvider>
        </NotificationsContextProvider>
      </DependencyProvider>
    </SWRConfig>
  );
};

export default App;

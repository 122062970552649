import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { mutate } from 'swr';

import { config } from '@/Consts/Config';

/**
 * SWR のすべてのキーに対してキャッシュをクリアする
 */
export const clearCache = () =>
  mutate(() => true, undefined, { revalidate: false });

export const useLogoutApp = () => {
  const { logout } = useAuth0();

  const logoutApp = useCallback(
    (query?: URLSearchParams) => {
      const url = new URL(config.MY_LOGOUT_URL);
      query?.forEach((v, k) => url.searchParams.append(k, v));

      clearCache();
      return logout({ logoutParams: { returnTo: url.href } });
    },
    [logout],
  );

  return {
    logoutApp,
  };
};

import { noPagingRoutes, tsuikyakuDetailRoutes } from '@/Routes';

const getPathPattern = (paths: string[]) =>
  paths.map(path => new RegExp(`^${path}`));

// ページングを伴わないクエリパラメータ変更が発生する画面パターン
const noPagingPatterns = getPathPattern(
  noPagingRoutes.map(route => route.path),
);

// 追客案件詳細を表示する画面パターン
const tsuikyakuDetailPatterns = getPathPattern(
  tsuikyakuDetailRoutes.map(route => route.path),
);

/**
 * ページ最上部に移動するべき画面遷移か判定する
 * @param from 遷移前パス
 * @param to 遷移後パス
 * @return ページ最上部に移動するべきかどうか
 */
export const isMoveScrollTop = (from: string, to: string) => {
  if (from === to) {
    // ページングを伴うクエリパラメータ変更の場合は移動する
    return noPagingPatterns.every(regex => !regex.test(from));
  }

  // 同一画面上で追客詳細を表示/非表示するパターンでない場合は移動する
  return tsuikyakuDetailPatterns.every(
    regex => !regex.test(from) || !regex.test(to),
  );
};

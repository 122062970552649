import { css } from '@emotion/react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { memo } from 'react';

import { useLogoutApp } from '@/Hooks/AppHooks';

type LoginErrorAction = {
  logout: () => Promise<void>;
};

const loginErrorRoot = css({
  bgcolor: 'background.default',
  display: 'flex',
  height: '100%',
  width: '100%',
  paddingTop: '100px',
  justifyContent: 'start',
});

/**
 * @private
 */
export const PlainLoginError = (props: LoginErrorAction): JSX.Element => (
  <Box css={loginErrorRoot} flexDirection="column">
    <Container maxWidth="md">
      <Box textAlign="center">
        <Grid container>
          <Grid item xs={12}>
            <Typography align="center" gutterBottom={true} variant="h4">
              認証に失敗しました
              <br />
              メールアドレスの確認、またはパスワードの再設定を行ってください
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={props.logout} size="large" variant="outlined">
              ログアウト
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </Box>
);

const LoginError = (): JSX.Element => {
  const { logoutApp } = useLogoutApp();
  return <PlainLoginError logout={() => logoutApp()} />;
};
export default memo(LoginError);

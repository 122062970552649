import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { config } from '@/Consts/Config';

export const Auth0ProviderWithNavigate = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    if (appState?.returnTo) {
      navigate(appState.returnTo);
    }
  };

  return (
    <Auth0Provider
      authorizationParams={{
        redirect_uri: config.MY_CALLBACK_URL,
        audience: config.AUTH0_AUDIENCE,
      }}
      clientId={config.AUTH0_CLIENT_ID}
      domain={config.AUTH0_DOMAIN}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};

import { ApiError, HttpError } from 'eb-sfa-core';

/**
 * 例外の発生原因がデータベース初期化未実施か否かを判定する
 * @param error 例外オブジェクト
 * @returns データベース初期化未実施が原因なら true
 */
export const isMissingInitialize = (error: Error): boolean => {
  if (error instanceof ApiError) {
    return error.response.code === 'common-0035';
  }
  return false;
};

/**
 * 404エラーか判定する
 * @param error
 * @returns 404エラーであれば true
 */
export const isNotFound = (error: Error): boolean => {
  if (error instanceof ApiError || error instanceof HttpError) {
    if (error.statusCode === 404) {
      return true;
    }
  }
  return false;
};

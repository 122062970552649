import { MaintApiError } from 'eb-sfa-core';
import { useExtendInterval } from 'eb-sfa-frontend-ui';
import { AppStatus, LoadState } from 'eb-sfa-models';
import useSWR from 'swr';

import { config } from '@/Consts/Config';

/**
 * 営業支援アプリの稼働ステータスを取得する
 * @returns 営業支援アプリの稼働ステータス
 */
export const useAppStatus = (): LoadState<AppStatus> => {
  const key = `${config.APP_STATUS_URL}/app_status.json`;
  const extendIntervalConfig = useExtendInterval<AppStatus>({
    type: 'medium',
    key,
  });
  const { data, error, isLoading } = useSWR<AppStatus>(
    key,
    (input: RequestInfo) =>
      fetch(input)
        .then(res => res.json())
        .catch(e => {
          throw new MaintApiError(e);
        }),
    {
      ...extendIntervalConfig,
    },
  );
  return {
    data,
    error,
    isLoading,
  };
};

import { getDateTimeFromISO, getDateTimeNow } from 'eb-sfa-core';
import {
  isNullOrUndefined,
  isNotNullOrUndefined,
  AppStatus,
  IncidentStatus,
} from 'eb-sfa-models';

/**
 * app_status.jsonの中身から受け取った障害の通知情報をもとに障害があるか判定を返す
 * @param status 通知ステータスのオブジェクト
 * @returns 障害通知が存在する場合 isValidをtrue。メッセージがあればdetailをオブジェクトで返す
 */
export const createIncidentStatus = (status: AppStatus): IncidentStatus => {
  const now = getDateTimeNow();
  const fromTime = status?.incident?.condition?.fromTime;
  const toTime = status?.incident?.condition?.toTime;
  const detail = status?.incident?.detail;
  if (isNullOrUndefined(fromTime) || isNullOrUndefined(toTime)) {
    return { isValid: false };
  }
  return isNotNullOrUndefined(status?.incident) &&
    (fromTime === '' || getDateTimeFromISO(fromTime) <= now) &&
    (toTime === '' || getDateTimeFromISO(toTime) >= now)
    ? { isValid: true, detail }
    : { isValid: false };
};

import { IncidentStatus } from 'eb-sfa-models';
import { isNullOrUndefined } from 'eb-sfa-models';
import { memo, createContext, useMemo } from 'react';

import { useAppStatus } from '@/Hooks/EboneApi/Status/AppStatusHooks';
import { createIncidentStatus } from '@/Utils/CreateIncidentStatus';

type NotificationsContextProps = {
  incident: IncidentStatus | null;
};
export const NotificationsContext = createContext<NotificationsContextProps>({
  incident: null,
});

export const NotificationsContextProvider = memo(
  ({ children }: { children: JSX.Element }): JSX.Element => {
    const { data, error, isLoading } = useAppStatus();
    const incident = useMemo(() => {
      if (isNullOrUndefined(error) && !isLoading && data) {
        return createIncidentStatus(data);
      }
      return { isValid: false };
    }, [error, isLoading, data]);

    const context = {
      incident,
    };
    return (
      <NotificationsContext.Provider value={context}>
        {children}
      </NotificationsContext.Provider>
    );
  },
);

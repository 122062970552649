import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from 'eb-sfa-frontend-ui';
import { ComponentType } from 'react';

export const AuthenticationGuard = ({
  component,
}: {
  component: ComponentType<object>;
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  return <Component />;
};

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { browserTracingIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { Loading, EnqueueSnackbarProvider } from 'eb-sfa-frontend-ui';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { config } from '@/Consts/Config';
import theme from '@/Themes/Theme';
import '@/Images/Icons/iconify';
import '@e-seikatsu/design-system/dist/tokens/index.css';

import App from './App';
import { Auth0ProviderWithNavigate } from './Auth0ProviderWithNavigate';
import { ReloadNotifierProvider } from './Contexts/ReloadNotifierContextProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (import.meta.env.VITE_MOCK_SERVER_WORKER === 'true') {
  if (import.meta.env.VITE_MOCK_SERVER_WORKER_AWAIT === 'true') {
    const browser = await import('./Mocks/Browser');
    await browser.worker.start({ onUnhandledRequest: 'bypass' });
  } else {
    import('./Mocks/Browser').then(browser => {
      browser.worker.start({ onUnhandledRequest: 'bypass' });
    });
  }
}

serviceWorkerRegistration.register();

Sentry.init({
  dsn: config.SENTRY_DSN,
  release: `${config.SENTRY_PACKAGE_NAME}@${config.APP_VERSION}`,
  integrations: [browserTracingIntegration()],
  tracesSampleRate: 0.001,
  ignoreErrors: ['MyApp_RemoveAllHighlights', 'removeAllHighlightsForSB'],
  denyUrls: ['http://localhost'],
});
Sentry.setTag('host', window.location.hostname);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <EmotionThemeProvider theme={theme}>
              <Suspense fallback={<Loading />}>
                <EnqueueSnackbarProvider>
                  <ReloadNotifierProvider>
                    <App />
                  </ReloadNotifierProvider>
                </EnqueueSnackbarProvider>
              </Suspense>
            </EmotionThemeProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </StrictMode>,
);

import { createContext, FC, ReactNode, useContext } from 'react';

import type { Services } from '@/Services/SetupDependencies';

const DependencyContext = createContext<Services | null>(null);

type DependencyProviderProps = {
  children: ReactNode;
  services: Services | null;
};

export const useDependency: <K extends keyof Services>(
  key: K,
) => Services[K] = key => {
  const services = useContext(DependencyContext);
  if (services === null) {
    throw new Error('Not found dependency');
  }
  return services[key];
};

export const DependencyProvider: FC<DependencyProviderProps> = ({
  children,
  services,
}) => {
  return (
    <DependencyContext.Provider value={services}>
      {children}
    </DependencyContext.Provider>
  );
};

import { isDifferentVersion } from 'eb-sfa-core';
import { useEnqueueSnackbar, ReloadNotifierSnackbar } from 'eb-sfa-frontend-ui';
import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { config } from '@/Consts/Config';
import { useVersion } from '@/Hooks/VersionHooks';

const updateIntervalMinutes = 5;
const updateIntervalMilliseconds = 1000 * 60 * updateIntervalMinutes;

const useReloadNotifier = (): void => {
  const { data: metadata } = useVersion(updateIntervalMilliseconds);
  const enqueueSnackbar = useEnqueueSnackbar();
  const isShown = useRef(false);

  /**
   * バージョンが異なる場合は、常に再読み込みすべき古いバージョンを使っていると判定
   */
  const isOldVersion = useMemo(() => {
    if (!metadata) {
      return false;
    }

    return isDifferentVersion(metadata.version, config.APP_VERSION);
  }, [metadata]);

  /**
   * 強制リロードフラグがあれば、強制的なリロードが必要と判定
   */
  const isForceReloadVersion = useMemo(
    () => metadata?.forceReload ?? false,
    [metadata?.forceReload],
  );

  const notifyUpdate = useCallback(() => {
    // 既にSnackbarが表示中の時は再表示しない
    if (isShown.current) {
      return;
    }

    if (isOldVersion) {
      const message = isForceReloadVersion
        ? `${Math.floor(updateIntervalMinutes)}分以内に画面を更新します。`
        : '画面を更新してください。';
      const variant = isForceReloadVersion ? 'error' : 'info';
      enqueueSnackbar(
        <ReloadNotifierSnackbar
          appName="賃貸/売買クラウド営業支援"
          message={message}
        />,
        {
          persist: true,
          variant,
          onClose: () => {
            isShown.current = false;
          },
        },
      );
      isShown.current = true;
    }
  }, [enqueueSnackbar, isForceReloadVersion, isOldVersion, isShown]);

  const checkAppUpdate = useCallback(async () => {
    if (isForceReloadVersion) {
      window.location.reload();
      return;
    }

    notifyUpdate();
  }, [isForceReloadVersion, notifyUpdate]);

  useEffect(
    () => notifyUpdate(),
    [notifyUpdate, isOldVersion, isForceReloadVersion],
  );
  useEffect(() => {
    const timer = setInterval(checkAppUpdate, updateIntervalMilliseconds);
    return () => clearInterval(timer);
  }, [checkAppUpdate]);
};

// TODO: void を使うのは何かが間違えているので、適切な型に修正する
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
const ReloadNotifierContext = createContext<void>(undefined);

export const ReloadNotifierProvider = memo(
  ({ children }: { children: ReactNode }): JSX.Element => (
    <ReloadNotifierContext.Provider value={useReloadNotifier()}>
      {children}
    </ReloadNotifierContext.Provider>
  ),
);
